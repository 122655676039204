@use '@/styles/utils/mixins.scss' as *;

.partnershipWrapper {
  display: flex;
  flex-direction: column;
  gap: 3.75rem;
  @include tab() {
    flex-direction: column;
  }

  .partnershipContentContainer {
    display: flex;
    gap: 1.25rem;
    flex-direction: column;

    @include tab() {
      gap: 3.75rem;
    }
  }

  .partnershipContentDetails {
    max-width: 35rem;
    width: 100%;
  }

  .partnershipTabsContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .quickLinkDivider {
    border-color: $primaryAlt !important;
    width: calc(100% - 7.5rem);
    min-width: auto;
    margin: 0 3.75rem;
    @include tab() {
      display: none;
    }
  }
  .partnershipTabsCardsList {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;

    @include tab() {
      grid-template-columns: repeat(2, 1fr);
    }

    @include mob() {
      grid-template-columns: repeat(1, 1fr);
      margin: 0 -1.875rem;
    }
  }
  .partnershipTabsCard {
    height: 100%;
    border-radius: 1.25rem;
    padding: 2.5rem 1.875rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.75rem;
    background-color: transparent;
    border: 0.063rem solid transparent;
    transition: all 0.2s ease-in-out;
    text-align: center;
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.active {
      -webkit-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
      -moz-box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
      box-shadow: 0rem 0.375rem 1rem 0rem rgba($primaryAlt, 0.35);
      border-color: $primaryAlt;
      background-color: $white;
    }

    .partnershipTabsCardImage {
      width: 20rem;
      height: 5.875rem;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        max-width: 100%;
        width: auto;
        height: auto;
        max-height: 5.875rem;
      }
    }
    .partnershipTabsCardBody {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 1.25rem;
      text-align: center;
    }

    .description {
      color: $neutral08;
    }
  }
  .tabContentContainer {
    display: flex;
    flex-direction: column;
    gap: 3.75rem;
  }
  .partnershipTabContent {
    margin-top: -10rem;
    margin-bottom: -15.25rem;
    @include tab() {
      margin-left: -1.875rem;
      margin-right: -1.875rem;
    }
  }
}
